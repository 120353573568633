/*Font*/
@font-face {
    font-family: GT Walsheim Pro;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: url('../public/fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Regular.woff2');
}

@font-face {
    font-family: GT Walsheim Pro;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF; /* Download only latin glyphs */
    src: url('../public/fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Bold.woff2');
}

@font-face {
    font-family: Kumlien Pro;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/*@font-face {
    font-family: Nunito Latin Regular;
    font-style: normal;
    font-display: swap;
    src: url('../public/fonts/Nunito/nunito-latin-regular.woff2');
}

@font-face {
    font-family: Nunito Latin Bold;
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url('../public/fonts/Nunito/nunito-latin-800.woff2');
}*/

@font-face {
    font-family: "Roboto Thin";
    font-style: normal;
    font-display: swap;
    src: url('../public/fonts/Roboto/Roboto-Thin.ttf');
}

@font-face {
    font-family: "Roboto Regular";
    font-style: normal;
    font-display: swap;
    src: url('../public/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: "Roboto Light";
    font-style: normal;
    font-display: swap;
    src: url('../public/fonts/Roboto/Roboto-Light.ttf');
}

/*Player*/
:root {
    --plyr-color-main: #00FF84;
    --plyr-video-progress-buffered-background: #00FF8466;
    --plyr-video-control-background-hover: transparent;
}

